.boton-amarillo {
    @include boton($amarillo , inline-block) ;
}
.boton-amarillo-block {
    @include boton($amarillo , block) ;
}

.boton-verde {
    @include boton($verde , inline-block) ;
}
.boton-naranja {
    @include boton($naranja , inline-block) ;
}

.boton-rojo-block {
    @include boton($rojo , block) ;
}

.boton-verde-block {
    @include boton($verde , block) ;
}