.contenedor {
    h2{
        font-weight: $regular;
        margin-bottom: 0.5rem;
        
    
    }
    .after::after{
        content:'';
        display: block;
        margin: auto;
        margin-top: 1px;
        width: 165px;
        height: 3px;
        background: #FB9A17;
        margin-bottom:45px;
        text-align: center;
      }
      p{
        text-align: center;
        font-size: 1.5rem;
        margin-top: 0.5rem;
      }
}



.sobre-nosotros{
    padding: 5rem 0;
    @include tablet{
        @include grid(2,5rem);
    }

    img{
        box-shadow: 0 0 8px 0 rgba(0,0,0,.5);
        
    }
}

.contenido-nosotros{
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2{
        margin-bottom: 2rem;        
    }
    p{
        text-align: justify;
    }
    
}

