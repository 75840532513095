.header{
    background-color: $negro;
    padding: 1rem 0 1rem 0;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.5);

    @include tablet {
        &.fijo {
            left: 0;
            top: 0;
            position: fixed;
            width: 100%;
            box-shadow: .1rem .2rem .3rem #333 ;
        }
    }
}