/** Media Queries **/
@mixin celular {
    @media (min-width: #{$celular}) {
        @content;
    }
}

@mixin telefono {
    @media (min-width: #{$telefono}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet}) {
        @content;
    }
}
  
@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}
@mixin grid($columnas, $espaciado ) {
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
}

@mixin boton ($bgColor, $display) {
    background-color: $bgColor;
    color: $blanco;
    font-weight: $bold;
    text-decoration: none;
    padding:  1rem 3rem;
    text-align: center;
    margin-top: calc($separacion / 2) ;
    display: $display;
    border:none ;

    &:hover {
        background-color: darken($bgColor,5);
        cursor: pointer;
    }
    
}