.barra {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    img{
        max-height: 10rem;
    }

    @include tablet{
        flex-direction: row;
        justify-content: space-between;

        
    }
}

.navegacion{

    @include tablet{
        display: flex;
        align-items: center;
    }

    a{
        color: $blanco;
        font-weight: $regular;
        text-decoration: none;
        font-size: 2.2rem;
        display: block;
        text-align: center;

        @include tablet {
            margin-right: 2rem;
            font-size: 2rem;

            &:last-of-type{
                margin-right: 0;
            }
        }

        &:hover{
            color: $naranja;
            border-bottom: 3px solid #FB9A17;
            transition: 0.3s;
        }
    }
}