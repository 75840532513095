.video{

    position: relative;
    max-height: 60rem;
    min-height: 10rem;
    overflow: hidden;

    .overlay {
        position: absolute;
        background: rgb(51,9,241);
        background: linear-gradient(270deg, rgba(51,9,241,0.2) 0%, rgba(10,7,105,0.2) 71%);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;      
    }


    video{
       width: auto;
       display: block;
        @include desktop {
            width: 100%;
        }
        @include telefono{
            width: 100%;
        }
        @include celular{
            width: 100%;
        }
    }
}

.contenido-video {

    z-index: 10;
    h2{
        color: $blanco;
        font-size: 4rem;
        font-weight: $bold;
        margin: 0;
        @include desktop {
            font-size: 7rem;
        }
       .tipo{
        color: $naranja;
        font-weight: 700;
       }
    }
    p{
        display: inline-block;
        padding: 1rem 2rem;
        color: $blanco;
        font-weight: 700;
        font-size: 2.4rem;
        margin: 0;
      
    }
    .titulo{
        font-size: 2.5rem;
        font-weight: normal;
        margin-bottom: 40px;
        @include desktop {
            font-size: 5rem;
        }
        
    }
}