@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

/** Media Queries **/
html {
  /* 1rem = 10px */
  font-size: 62.5%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  line-height: 1.8;
}
@media (min-width: 768px) {
  body.body-scroll {
    padding-top: 119.98px;
  }
}

p {
  color: #000000;
  font-size: 2rem;
}

.contenedor {
  width: 95%;
  max-width: 120rem;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

img,
picture {
  width: 100%;
  display: block;
}

h1, h2, h3 {
  font-family: "Lato", sans-serif;
  margin: 2.5rem 0;
  font-weight: 300;
  text-align: center;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 3.4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.6rem;
}

.seccion {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.contenido-centrado {
  max-width: 80rem;
}

.alinear-derecha {
  display: flex;
  justify-content: flex-end;
}

.boton-amarillo {
  background-color: #E08709;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  border: none;
}
.boton-amarillo:hover {
  background-color: #c77808;
  cursor: pointer;
}

.boton-amarillo-block {
  background-color: #E08709;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  border: none;
}
.boton-amarillo-block:hover {
  background-color: #c77808;
  cursor: pointer;
}

.boton-verde {
  background-color: #71B100;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  border: none;
}
.boton-verde:hover {
  background-color: #619800;
  cursor: pointer;
}

.boton-naranja {
  background-color: #FB9A17;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: inline-block;
  border: none;
}
.boton-naranja:hover {
  background-color: #f48e04;
  cursor: pointer;
}

.boton-rojo-block {
  background-color: rgb(189, 7, 7);
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  border: none;
}
.boton-rojo-block:hover {
  background-color: #a40606;
  cursor: pointer;
}

.boton-verde-block {
  background-color: #71B100;
  color: #FFFFFF;
  font-weight: 700;
  text-decoration: none;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 2.5rem;
  display: block;
  border: none;
}
.boton-verde-block:hover {
  background-color: #619800;
  cursor: pointer;
}

/** UI Layout **/
.header {
  background-color: #000000;
  padding: 1rem 0 1rem 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) {
  .header.fijo {
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    box-shadow: 0.1rem 0.2rem 0.3rem #333;
  }
}

.barra {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}
.barra img {
  max-height: 10rem;
}
@media (min-width: 768px) {
  .barra {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .navegacion {
    display: flex;
    align-items: center;
  }
}
.navegacion a {
  color: #FFFFFF;
  font-weight: 400;
  text-decoration: none;
  font-size: 2.2rem;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .navegacion a {
    margin-right: 2rem;
    font-size: 2rem;
  }
  .navegacion a:last-of-type {
    margin-right: 0;
  }
}
.navegacion a:hover {
  color: #FB9A17;
  border-bottom: 3px solid #FB9A17;
  transition: 0.3s;
}

.video {
  position: relative;
  max-height: 60rem;
  min-height: 10rem;
  overflow: hidden;
}
.video .overlay {
  position: absolute;
  background: rgb(51, 9, 241);
  background: linear-gradient(270deg, rgba(51, 9, 241, 0.2) 0%, rgba(10, 7, 105, 0.2) 71%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.video video {
  width: auto;
  display: block;
}
@media (min-width: 1024px) {
  .video video {
    width: 100%;
  }
}
@media (min-width: 480px) {
  .video video {
    width: 100%;
  }
}
@media (min-width: 360px) {
  .video video {
    width: 100%;
  }
}

.contenido-video {
  z-index: 10;
}
.contenido-video h2 {
  color: #FFFFFF;
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
}
@media (min-width: 1024px) {
  .contenido-video h2 {
    font-size: 7rem;
  }
}
.contenido-video h2 .tipo {
  color: #FB9A17;
  font-weight: 700;
}
.contenido-video p {
  display: inline-block;
  padding: 1rem 2rem;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 2.4rem;
  margin: 0;
}
.contenido-video .titulo {
  font-size: 2.5rem;
  font-weight: normal;
  margin-bottom: 40px;
}
@media (min-width: 1024px) {
  .contenido-video .titulo {
    font-size: 5rem;
  }
}

.contenedor h2 {
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.contenedor .after::after {
  content: "";
  display: block;
  margin: auto;
  margin-top: 1px;
  width: 165px;
  height: 3px;
  background: #FB9A17;
  margin-bottom: 45px;
  text-align: center;
}
.contenedor p {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.sobre-nosotros {
  padding: 5rem 0;
}
@media (min-width: 768px) {
  .sobre-nosotros {
    display: grid;
    gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
.sobre-nosotros img {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
}

.contenido-nosotros {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contenido-nosotros h2 {
  margin-bottom: 2rem;
}
.contenido-nosotros p {
  text-align: justify;
}

.sobre-servicios {
  padding: 5rem 0;
}
@media (min-width: 768px) {
  .sobre-servicios {
    display: grid;
    gap: 7rem;
    grid-template-columns: repeat(4, 1fr);
  }
}
.sobre-servicios img {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
}

.contenido-servicios p {
  font-weight: 700;
}

.contenedor ul {
  text-align: center;
  padding-left: 0;
}

.botones-work {
  overflow: hidden;
}
.botones-work li {
  display: inline-block;
  text-align: center;
  margin-left: 1rem;
  margin-bottom: 4.5rem;
  padding: 6px 12px;
  border: 1px solid rgb(219, 144, 1);
  list-style: none;
  color: rgb(219, 144, 1);
}
.botones-work li:hover {
  background: rgb(219, 144, 1);
  color: #fff;
  cursor: pointer;
}
.botones-work .active {
  background: rgb(219, 144, 1);
  color: #fff;
}

.galeria-work {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.galeria-work .cont-work {
  width: 31%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  height: 300px;
  overflow: hidden;
  margin-bottom: 4.5rem;
}

.img-work {
  height: 90%;
  width: 100%;
}
.img-work img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.textos-work {
  height: 10%;
  text-align: center;
}
.textos-work h4 {
  line-height: 0.5px;
  font-weight: 700;
  font-size: 1.2rem;
}

.formulario p {
  font-size: 1.4rem;
  color: #333333;
  margin: 2rem 0 0 0;
}
.formulario legend {
  font-size: 2rem;
  color: #333333;
}
.formulario label {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
.formulario input:not([type=submit], [type=reset]),
.formulario textarea, .formulario select {
  padding: 1rem;
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #e1e1e1;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.formulario textarea {
  height: 20rem;
}
.formulario .forma-contacto {
  max-width: 30rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.formulario input[type=radio] {
  margin: 0;
  width: auto;
}

/** Paginas Internas **/