
.sobre-servicios{
    padding: 5rem 0;
    @include tablet{
        @include grid(4,7rem);
    }

    img{
        box-shadow: 0 0 8px 0 rgba(0,0,0,.5);
        
    }
}

.contenido-servicios{
    p{
        font-weight: $bold;
    }
}