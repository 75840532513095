.contenedor {
    ul{
        text-align: center;
        padding-left: 0;
    }
}
.botones-work{
    overflow: hidden;
    li{
        display: inline-block;
        text-align: center;
        margin-left: 1rem;
        margin-bottom: 4.5rem;
        padding: 6px 12px;
        border: 1px solid rgb(219,144,.5);
        list-style: none;
        color: rgb(219,144,.5);

        &:hover{
            background: rgb(219,144,.5);
            color:#fff;
            cursor: pointer;
        }
    }
    .active{
        background: rgb(219,144,.5);
        color:#fff;
    }
}

.galeria-work{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .cont-work{
        width: 31%;
        box-shadow: 0 0 6px 0 rgba(0,0,0,.5);
        height:300px;
        overflow: hidden;
        margin-bottom: 4.5rem;
        }        
  }

  .img-work{
    height: 90%;
    width: 100%;

    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.textos-work{
    height: 10%;
    text-align: center;

    h4{
        line-height: 0.5px;
        font-weight: $bold;
        font-size: 1.2rem;
    }
} 